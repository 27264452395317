/* eslint-disable radix */
/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import React from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@material-ui/core';
import {
  faCopy,
  faEye,
  faHistory,
  faInfoCircle,
  faLink,
  faMapMarkerAlt,
  faPenAlt,
  faPercent,
  faPrint,
  faRedo,
  faTools,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { useHistory } from 'react-router';
import FlexMenu from '../../../headless-menu/FlexMenu';
import { removeDiscount, updateTotalDiscount } from '../../../../../redux/actions/discountActions';
import { openModal } from '../../../../../redux/actions/modalActions';
import {
  addOrderToCart,
  desactivePromotions,
  toggleOpenDetail,
  updateTotals,
} from '../../../../../redux/actions/cartActions';
import { handleEditOrder, selectOrder } from '../../../../../redux/actions/orderActions';
import { setDiscountTaxes } from '../../../../../redux/actions/filtersActions';
import SatLogo from '../../../../Icons/SatLogo';
import {
  changeSalesOrderStatus,
  saveSell,
} from '../../../../../redux/actions/sellActions';
import { cloneQuote } from '../../../../../redux/actions/cotizarActions';
import { fetchTimbrado } from '../../../../../redux/actions/TimbrarActions';
import {
  shouldRefoundMoney,
  shouldRefoundProducts,
} from '../../../../../helpers/order';
import { addItemsToTable } from '../../../../../redux/actions/tableActions';
import { changeTab } from '../../../../../redux/actions/tabActions';

const CartSaleMenu = () => (
  <FlexMenu>
    <FlexMenu.Trigger
      as={IconButton}
      aria-label="Menu"
      style={{
        borderRadius: '5px',
        height: 40,
        border: '1px solid #b8b8b8',
      }}
    >
      <FontAwesomeIcon icon={faTools} style={{ fontSize: '16px' }} />
    </FlexMenu.Trigger>
    <FlexMenu.OptionList as={Paper} position="left" style={{ width: 230 }}>
      <RenderMenuList />
    </FlexMenu.OptionList>
  </FlexMenu>
);
export default CartSaleMenu;

const RenderMenuList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const discountTaxes = useSelector(state => state.filters.discountTaxes);
  const authUser = useSelector(state => state.authUser);
  const tabs = useSelector(state => state.tabs);
  const loads = useSelector(state => state.loads);
  const cart = useSelector(state => state.cart);
  const enableEditOrder = useSelector(state => state.order.enableEditOrder);
  const { posTypeTab } = tabs;
  const {
    orderSelected, balance, total, openDetail,
  } = cart;
  const { order } = orderSelected;
  const {
    domain,
    user: {
      almacenes,
      config: { store, pos_no_facturar, sostatus, pos_allowed_sostatus, pos_no_descuentos, pos_no_sin_impuestos, pos_no_devoluciones_a },
    },
  } = authUser;
  const estadosat = orderSelected ? orderSelected.estadosat : '';
  const lastFacturaDate = orderSelected ? orderSelected.f_ultima_factura : '';
  const cfdis = orderSelected ? Number.parseInt(orderSelected.cfdis) : 0;
  const isOrder = orderSelected.order && orderSelected.order.hasOwnProperty('salesorder_no');
  const isQuote = orderSelected.order && orderSelected.order.hasOwnProperty('quote_no');
  const enableTimbrar = Boolean(
    isOrder
      && (lastFacturaDate === ''
        || lastFacturaDate == null
        || lastFacturaDate === '0000-00-00 00:00:00')
      && (estadosat === 'Sin Factura' || estadosat == null)
      && cfdis === 0,
  );

  const allowOrderStatus = pos_allowed_sostatus.split(' |##| ');
  const enableEdit = Boolean(
    order && isOrder ? ((order.estado_mda === 'Sin Entregar' || order.estado_mda === 'N/A' || order.estado_mda === 'Sin Recibir')
        && allowOrderStatus.includes(order.sostatus)
        && (order.estadosat === 'Cancelado' || order.estadosat === 'Sin Factura' || order.estadosat == null)
        && (order.sostatus === 'Creado' || order.sostatus === 'Apartado')
        && order.estado_fde === 'Sin Pago/Cobro'
    ) : isQuote && Boolean(posTypeTab === 'Cotizaciones'),
  );

  const canMakeInvoice = pos_no_facturar === '1' ? true : !enableTimbrar;
  const almacen = orderSelected ? orderSelected.cartStateWarehouse : '';
  const selectStore = almacenes && almacenes.length
    ? almacenes.find(alm => alm.crmid === store.crmid)
    : null;
  const enableDiscountSellTaxes = Boolean(
    selectStore && selectStore.pos_forzaventasiniva === '1',
  );
  const enableToSee = Boolean(
    cart.products.length > 0
      && (posTypeTab === 'Historico' || posTypeTab === 'Cotizaciones'),
  );
  const enableRefundMoney = Boolean(shouldRefoundMoney(balance, total));
  const enableRefundProdu = Boolean(shouldRefoundProducts(almacen || ''));
  const enableRefund = Boolean(
    isOrder && (enableRefundMoney || enableRefundProdu),
  );
  const disableButton = !isOrder || orderSelected?.order?.estado_mda === 'Entregado';
  const enable = Boolean(
    !loads.orderIsLoading && posTypeTab === 'Vender' && cart.totalProducts > 0,
  );
  const canApplyDiscount = pos_no_descuentos === '0' && enable; /* enable */
  const canDisableTaxes = pos_no_sin_impuestos === '0';

  const applyDiscount = () => {
    dispatch(removeDiscount());
    dispatch(openModal('discount'));
  };

  const toggleTaxesDiscount = async () => {
    await dispatch(setDiscountTaxes());
    if (posTypeTab === 'Vender' && !order) {
      dispatch(updateTotals());
    } else {
      // dispatch(addOrderToCart(order));
      dispatch(updateTotalDiscount());
      if (
        !enableEditOrder
        && Object.keys(orderSelected).length
        && posTypeTab === 'Historico'
      ) {
        dispatch(handleEditOrder());
      }
    }
  };

  const handleClone = () => {
    if (isOrder) {
      dispatch(changeSalesOrderStatus(sostatus));
      dispatch(saveSell());
      // dispatchMakeSell(sostatus);
    }
    if (isQuote) {
      dispatch(cloneQuote());
    }
  };

  const goToCrm = () => {
    let url = '';
    if (posTypeTab === 'Cotizaciones') {
      url = `https://${domain}/index.php?module=Quotes&view=Detail&record=${orderSelected.crmid}`;
    }

    if (posTypeTab === 'Historico') {
      url = `https://${domain}/index.php?module=SalesOrder&view=Detail&record=${orderSelected.crmid}`;
    }
    window.open(url, '_blank');
  };

  const seeOnMap = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${
      order.ship_street || ''
    }, ${order.ship_pobox || ''}, ${order.ship_country || ''}, ${
      order.ship_state || ''
    }, ${order.ship_code || ''}`;
    window.open(url, '_blank');
  };

  const handleTimbrar = () => {
    dispatch(fetchTimbrado(orderSelected.crmid));
    dispatch(openModal('timbrar'));
  };

  const handleRefound = () => {
    dispatch(openModal('refund'));
    dispatch(addItemsToTable('refund'));
  };

  const handleUpdateOrder = async () => {
    // dispatch(desactivePromotions());
    // dispatchClearCart();
    await dispatch(handleEditOrder());
    if (enableEditOrder) {
      await dispatch(changeTab('posType', 'Historico'));
      history.push('/pos/ventas');
      await dispatch(selectOrder(orderSelected.crmid));
    } else {
      if (posTypeTab === 'Cotizaciones') {
        await dispatch(changeTab('posType', 'Vender'));
        history.push('/pos/vender');
      }
      if (posTypeTab === 'Historico') {
        await dispatch(changeTab('posType', 'Vender'));
        history.push('/pos/vender');
      }
    }
  };

  return (
    <>
      {posTypeTab !== 'Vender' && (
        <List component="nav">
          <ListItem button disabled={!enableEdit} onClick={handleUpdateOrder}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faPenAlt} style={{ fontSize: '16px' }} />
            </ListItemIcon>
            <ListItemText primary={posTypeTab === 'Historico' ? 'Editar orden' : 'Editar cotización'} />
          </ListItem>

          <ListItem button disabled={posTypeTab === 'Vender'} onClick={() => dispatch(toggleOpenDetail(!openDetail))}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '16px' }} />
            </ListItemIcon>
            <ListItemText primary="Información" />
          </ListItem>

          <ListItem button disabled={!enableToSee} onClick={goToCrm}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faEye} style={{ fontSize: '16px' }} />
            </ListItemIcon>
            <ListItemText primary="Ver en sistema" />
          </ListItem>

          <ListItem
            button
            disabled={!cart.products.length}
            onClick={() => dispatch(openModal('printOption'))}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faPrint} style={{ fontSize: '16px' }} />
            </ListItemIcon>
            <ListItemText primary="Imprimibles" />
          </ListItem>

          <ListItem button disabled={!enableToSee} onClick={seeOnMap}>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ fontSize: '16px' }}
              />
            </ListItemIcon>
            <ListItemText primary="Google map" />
          </ListItem>

          <ListItem button onClick={() => dispatch(openModal('shareUrl'))}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faLink} style={{ fontSize: '16px' }} />
            </ListItemIcon>
            <ListItemText primary="Enlaces" />
          </ListItem>
          {
            posTypeTab === 'Historico' && pos_no_devoluciones_a !== '1' && (
              <ListItem button disabled={!enableRefund} onClick={handleRefound}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faRedo} style={{ fontSize: '16px' }} />
                </ListItemIcon>
                <ListItemText primary="Devolución" />
              </ListItem>
            )
          }
          <ListItem
            button
            disabled={!isOrder && !isQuote}
            onClick={handleClone}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faCopy} style={{ fontSize: '16px' }} />
            </ListItemIcon>
            <ListItemText primary="Clonar" />
          </ListItem>

          {
            posTypeTab === 'Historico' && (
              <>
                <ListItem button disabled={disableButton} onClick={() => dispatch(openModal('externalDelivery'))}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faHistory} style={{ fontSize: '16px' }} />
                  </ListItemIcon>
                  <ListItemText primary="Entrega externa" />
                </ListItem>

                <ListItem button disabled={!enableTimbrar} onClick={handleTimbrar}>
                  {/* <ListItemIcon> */}
                  <SatLogo
                    style={{ height: 25 }}
                    fill={!canMakeInvoice ? '#303c52' : '#c7c7c7'}
                  />
                  {/* </ListItemIcon> */}
                  <ListItemText primary="Timbrar" />
                </ListItem>
              </>
            )
          }
        </List>
      )}
      {posTypeTab === 'Vender' && (
        <List component="nav">
          {
            canApplyDiscount && (
              <ListItem
                button
                disabled={!cart.products.length}
                onClick={applyDiscount}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faPercent} style={{ fontSize: '18px' }} />
                </ListItemIcon>
                <ListItemText primary="Descuento General" />
              </ListItem>
            )
          }
          {/* <ListItem button disabled={!enableChangeListPrice() && arePricebooks}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faAddressBook} style={{ fontSize: '18px' }} />
              </ListItemIcon>
              <ListItemText primary="Cambiar precio" />
            </ListItem> */}
          <ListItem button onClick={() => dispatch(openModal('shareUrl'))}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faLink} style={{ fontSize: '18px' }} />
            </ListItemIcon>
            <ListItemText primary="Enlaces" />
          </ListItem>
          <ListItem
            button
            disabled={!cart.products.length}
            onClick={() => dispatch(openModal('printOption'))}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faPrint} style={{ fontSize: '18px' }} />
            </ListItemIcon>
            <ListItemText primary="Imprimibles" />
          </ListItem>
          {
            canDisableTaxes && (
              <ListItem
                button
                disabled={enableDiscountSellTaxes}
                onClick={toggleTaxesDiscount}
              >
                <ListItemIcon>
                  {discountTaxes ? <CheckBox /> : <CheckBoxOutlineBlank />}
                </ListItemIcon>
                <ListItemText primary="Descontar impuestos" />
              </ListItem>
            )
          }
        </List>
      )}
    </>
  );
};
